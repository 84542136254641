<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const props = defineProps<{
  to: string;
}>();

const link = computed(() => {
  return `/${locale.value}${props.to}`;
});
</script>

<template>
  <RouterLink v-bind="$props" :to="link">
    <slot />
  </RouterLink>
</template>
